import { createTheme, responsiveFontSizes } from "@mui/material"

const clientSpacing = [0, 8, 12, 14, 16, 20, 24, 28, 33, 35, 41, 48, 50, 58, 67, 72, 84, 96, 102]

let defaultTheme = createTheme({
  spacing: [...clientSpacing],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1013,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#d63544',
      light: '#FFEBEB'
    },
    secondary: {
      main: '#f6f6ee',
    },
    tertiary: {
      main: 'rgba(93, 83, 64, 1)',
    },
    text: {
      primary: 'rgba(93, 83, 64, 1)',
    },
    background: {
      default: '#f6f6ee',
      main: '#f6f6ee',
    },
    white: {
      main: '#ffffff',
    },
    highlight: {
      main: '#698f68',
    },
    yellow: {
      main: '#F6C305',
      light: 'rgba(93, 83, 64, 0.4)'
    }
  },
  typography: {
    fontSize: 18,
    body1: {
      fontFamily: 'LeagueSpartan-Regular',
      fontSize: 18,
      lineHeight: 1.5,
      letterSpacing: '0em',
    },
    body2: {
      fontFamily: 'LeagueSpartan-Regular',
      fontSize: 14,
      letterSpacing: '0em',
    },
    button: {
      lineHeight: 1.43,
    },
    caption: {
      fontFamily: 'LeagueSpartan-Regular',
      fontSize: 12,
      lineHeight: 1.5,
      letterSpacing: '0em',
    },
    overline: {
      fontFamily: 'LeagueSpartan-Regular',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      letterSpacing: 0.3,
    },
    h1: {
      fontSize: clientSpacing[15],
      fontFamily: 'Sentient-Bold',
    },
    h2: {
      fontSize: clientSpacing[11],
      fontFamily: 'Sentient-Bold',
    },
    h3: {
      fontSize: clientSpacing[9],
      fontFamily: 'Sentient-Bold',
    },
    h4: {
      fontSize: clientSpacing[7],
      fontFamily: 'Sentient-Bold',
      letterSpacing: '0em',
    },
    h5: {
      fontSize: clientSpacing[5],
      fontFamily: 'Sentient-Bold',
    },
    h6: {
      fontSize: clientSpacing[4],
      fontFamily: 'Sentient-Bold',
      letterSpacing: '0em',
    },
  },
})

let clientTheme = createTheme({
  spacing: [...clientSpacing],
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 900,
      lg: 1180,
      xl: 1300,
      xxl: 1440
    }
  },
  typography: defaultTheme.typography,
  palette: defaultTheme.palette,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      // *{
      //   border: 1px solid tomato;
      // }
      @keyframes arrowAnimation {
        0%,
        50%,
        100% {
          translateX: 10px;
          opacity: 1
        }
        25%,
        75% {
          translateX: 0px;
          opacity: 0.2
        }
      }
      a{
        color: inherit;
        text-decoration: none;
      }
      .backgroundWrapperClasses{
        position: fixed;
        z-index: 10;
        left: 0;
        right: 0;
        bottom: 0;
        padding: ${defaultTheme.spacing(6)};
        background-color: ${defaultTheme.palette.background.main};
        display: flex;
        align-items: flex-end;
        box-shadow: 0px 0px 9px 0px rgba(61, 64, 59, 0.2);
      }
      .cookie-notice {
        display: flex;
        column-gap: ${defaultTheme.spacing(6)};
        row-gap: ${defaultTheme.spacing(6)};
        justify-content: flex-end;
      }
      ${[defaultTheme.breakpoints.down('md')]}{
        .cookie-notice{
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
        }
      }
      .backgroundClasses{
        display: flex;
        column-gap: ${defaultTheme.spacing(12)};
        width: 100%;
        justify-content: space-between;
      }
      ${[defaultTheme.breakpoints.down('sm')]}{
        .backgroundClasses{
          flex-direction: column;
        }
      }
      .buttonWrapperClasses{
        display: flex;
        column-gap: ${defaultTheme.spacing(6)};
        align-items: center;
      }
      .cookieButton{
        padding-top: ${defaultTheme.spacing(1)};
        padding-bottom: ${defaultTheme.spacing(1)};
        padding-left: ${defaultTheme.spacing(6)};
        padding-right: ${defaultTheme.spacing(6)};
        border-radius: 1000px;
        text-transform: uppercase;
        border: none;
        outline: none;
      }
      .buttonOutlined{
        background-color: transparent;
        border: 1px solid ${defaultTheme.palette.tertiary.main};
        color: ${defaultTheme.palette.tertiary.main};
      }
      .buttonTertiary{
        border: 1px solid ${defaultTheme.palette.text.main};
        color: ${defaultTheme.palette.text.main};
        align-self: center;
      }
      .acceptButtonClasses{
        background-color: ${defaultTheme.palette.primary.main};
        color: ${defaultTheme.palette.white.main};
      }
      .declineButtonClass{
         
      }
      .cookie-list{
        color: ${defaultTheme.palette.white.main};
      }
      .bg-cookie{
        .btn-primary, .btn-secondary {             
          outline: none;
          border: none;
          font-family: Open Sans;
          border-radius: 1000;
          display: flex;
          align-items: center;
          justify-content: center;
          width: max-content;
          font-weight: 400;
          font-size: ${defaultTheme.spacing(2)}; 
          &:hover{
            cursor: pointer;
          }           
        }
        .btn-primary {
          color: ${defaultTheme.palette.white.main};
          background-color: ${defaultTheme.palette.primary.main};
        }
        .btn-secondary {
          color: ${defaultTheme.palette.white.main};
          background-color: transparent;
          border: 1px solid ${defaultTheme.palette.primary.main};
        }
        .float-end{
          display: flex;
          column-gap: 6;
        }
      }
    `
    },
    MuiContainer: {
      styleOverrides: {
        root:{
          
        },
        maxWidthXl:{
          [defaultTheme.breakpoints.down('lg')]: {
            paddingLeft: `${defaultTheme.spacing(8)} !important`,
            paddingRight: `${defaultTheme.spacing(8)} !important`,
          },
          [defaultTheme.breakpoints.down('sm')]: {
            paddingLeft: `${defaultTheme.spacing(5)} !important`,
            paddingRight: `${defaultTheme.spacing(5)} !important`,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          paddingBottom: 4,
          "&:before": {
            backgroundColor: defaultTheme.palette.background.default,
            opacity: '1 !important',
          },

        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          columnGap: 10,
          padding: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: defaultTheme.palette.tertiary.main,
        },
        light: {
          borderColor: defaultTheme.palette.background.default,
        },
        accent: {
          borderColor: defaultTheme.palette.primary.main,
        }
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.white.main,
        },
      },
    },
    MuiTextFeild: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: 0,
          },
          borderRadius: 0
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.background.default,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {

        },
        colorPrimary: {
          backgroundColor: defaultTheme.palette.background.default,
          '&:disabled': {
            opacity: 0.3,
            backgroundColor: defaultTheme.palette.background.default,
          }
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontFamily: 'LeagueSpartan-Regular',
          borderRadius: 100,
          textTransform: 'unset',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'max-content',
          fontWeight: 400,
          padding: `${defaultTheme.spacing(1)} ${defaultTheme.spacing(5)}`,
        },
        sizeLarge: {
          display: "flex",
          padding: `${defaultTheme.spacing(1)} ${defaultTheme.spacing(5)}`, //"0.5rem 1.3125rem",
          justifyContent: "center",
          alignItems: "center",
          fontSize: defaultTheme.spacing(4)
        },
        sizeSmall: {
          display: "flex",
          padding: `4px ${defaultTheme.spacing(2)} !important`, //"0.5rem 1.3125rem",
          justifyContent: "center",
          alignItems: "center",
          fontSize: defaultTheme.spacing(2),
        },
        outlineTertiary: {
          color: defaultTheme.palette.text.primary,
          backgroundColor: defaultTheme.palette.background.default,
          border: `1px solid ${defaultTheme.palette.text.primary}`,
          '&:hover': {
            cursor: 'pointer'
          },
        },
        outlineSecondary: {
          color: defaultTheme.palette.background.main,
          backgroundColor: 'transparent',
          border: `1px solid ${defaultTheme.palette.background.main}`,
          '&:hover': {
            cursor: 'pointer'
          },
        },
        textPrimary: {
          color: defaultTheme.palette.primary.main,
          borderRadius: 0,
          padding: `${defaultTheme.spacing(1)} ${defaultTheme.spacing(1)}`,
          '&:hover': {
            color: defaultTheme.palette.tertiary.main,
            backgroundColor: 'transparent',
            '& .MuiSvgIcon-root': {
              color: defaultTheme.palette.tertiary.main,
              // animation: 'arrowAnimation 2s ease 0s infinite normal forwards',
              // transition: 'all 0.2s ease-in-out 0s',
            },
          },
        },
        textTertiary: {
          color: defaultTheme.palette.tertiary.main,
          borderRadius: 0,
          padding: `${defaultTheme.spacing(1)} ${defaultTheme.spacing(1)}`,
          '&:hover': {
            color: defaultTheme.palette.tertiary.main,
            backgroundColor: 'transparent'
          },
        },
        containedPrimary: {
          color: 'white',
          backgroundColor: defaultTheme.palette.primary.main,
          border: `1px solid ${defaultTheme.palette.primary.main}`,
          '&:hover': {
            cursor: 'pointer',

          },
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {},
        h2: {
          [defaultTheme.breakpoints.up('xs')]: {
            paddingTop: `${defaultTheme.spacing(6)} !important`,
            paddingBottom: `${defaultTheme.spacing(6)} !important`,
            marginTop: 0,
            marginBottom: 0,
          },
          [defaultTheme.breakpoints.up('md')]: {
            paddingTop: `${defaultTheme.spacing(6)} !important`,
            paddingBottom: `${defaultTheme.spacing(6)} !important`,
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.white.main,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0, 
        },
        notchedOutline:{
          border: `1px solid ${defaultTheme.palette.tertiary.main}`,
        },
      },
    },

    MuiCheckbox:{
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        colorPrimary:{
          borderRadius: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          //backgroundColor: 'red',
        },
      },
    },
  },
});

clientTheme = responsiveFontSizes(clientTheme);

export default clientTheme