import React from "react"
import { SvgIcon, useTheme, Box } from "@mui/material"

export const Icons = (props) => {
  
  const theme = useTheme()
  
  const {type, colour = theme.palette.primary.main } = props

  let iconType = ""

  switch (type) {
    case "bud":
      iconType = (
        <SvgIcon>
          <svg
            width="32"
            height="21"
            viewBox="0 0 32 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.3931 3.41149L15.9816 0L12.6071 3.37446C11.3405 4.64109 10.6132 6.24281 10.4252 7.89426C8.82385 7.31136 7.03383 7.19719 5.26286 7.67172L0 9.0819L1.39487 14.2876C2.62453 18.8768 7.34163 21.6002 11.9308 20.3705L15.9999 19.2802L20.0692 20.3706C24.6584 21.6003 29.3755 18.8769 30.6051 14.2877L32 9.08196L26.7371 7.67178C24.9647 7.19686 23.1732 7.31161 21.5709 7.89577C21.3765 6.25688 20.6506 4.66904 19.3931 3.41149ZM5.26286 7.67175L0 9.08193L1.39487 14.2877C2.62453 18.8768 7.34163 21.6002 11.9308 20.3706L16 19.2802L20.0692 20.3706C24.6584 21.6002 29.3755 18.8768 30.6051 14.2877L32 9.08193L26.7371 7.67175C22.148 6.44208 17.4309 9.1655 16.2012 13.7547L16 14.5056L15.7988 13.7547C14.5691 9.1655 9.85202 6.44208 5.26286 7.67175ZM5.75284 9.50038C9.33208 8.54132 13.0111 10.6654 13.9702 14.2446L14.875 17.6217L11.4408 18.5419C7.86158 19.501 4.18256 17.3769 3.2235 13.7977L2.31861 10.4206L5.75284 9.50038ZM26.2472 9.50038C22.6679 8.54132 18.9889 10.6654 18.0298 14.2446L17.125 17.6217L20.5592 18.5419C24.1384 19.501 27.8174 17.3769 28.7765 13.7977L29.6814 10.4206L26.2472 9.50038Z"
              fill={colour}
            />
          </svg>
        </SvgIcon>
      )
      break
    case "acorn":
      iconType = (
        <SvgIcon>
          <svg
            width="19"
            height="22"
            viewBox="0 0 19 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.09258 9.75774C-0.886216 6.43651 -1.23562 1.29997 9.40087 0.665771C20.0333 1.29972 19.6882 6.4326 17.7114 9.75394C17.3866 9.1505 16.8976 8.7133 16.4722 8.42449C15.7517 7.93532 14.8786 7.62431 14.0934 7.41451C12.4962 6.98771 10.6557 6.82495 9.45052 6.79614L9.42692 6.79558L9.40302 6.79501L9.37913 6.79558L9.35553 6.79614C8.15036 6.82495 6.30984 6.98771 4.7126 7.41451C3.92744 7.62431 3.0543 7.93532 2.33384 8.42449C1.90758 8.71391 1.41736 9.15233 1.09258 9.75774ZM4.72951 18.5868C3.73412 17.2857 2.92629 15.344 2.62244 13.1625C2.60097 12.5672 2.63135 11.9332 2.72381 11.2587L2.73058 11.2093L2.73241 11.1595C2.7478 10.7393 3.01488 10.3795 3.45728 10.0791C3.90829 9.77293 4.53347 9.53254 5.2289 9.34672C6.61996 8.97501 8.28966 8.82221 9.40302 8.79558C10.5164 8.82221 12.1861 8.97501 13.5771 9.34672C14.2726 9.53254 14.8978 9.77293 15.3488 10.0791C15.7912 10.3795 16.0582 10.7393 16.0736 11.1595L16.0755 11.2093L16.0822 11.2587C16.1747 11.9334 16.2051 12.5675 16.1836 13.1629C15.8797 15.344 15.0721 17.2853 14.0769 18.5864C13.9696 18.7101 13.8607 18.8291 13.7507 18.9435C12.2275 20.5271 10.5033 21.2166 10.2214 21.3197C10.0356 21.3876 9.71919 21.4217 9.40302 21.4217C9.08686 21.4217 8.7704 21.3876 8.58464 21.3197C8.30279 21.2166 6.57856 20.5271 5.05534 18.9435C4.94547 18.8293 4.83666 18.7104 4.72951 18.5868Z"
              fill={colour}
            />
          </svg>
        </SvgIcon>
      )
      break
    default:
      iconType = null
  }
  return <Box sx={{
    svg: {
      height: 20.67
    }
  }}>{iconType}</Box>
}
